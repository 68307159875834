<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div class="hidden-sm-and-down">
      <div
        class="section1 pb-16 small"
      >
        <sequential-entrance
          from-top
        >
          <v-row class="ml-16">
            <v-col class="pt-16">
              <div class="title-text-small mt-16">
                <v-row><v-col>Get a <span class="primary--text">landlord</span> insurance <br> quote and apply online</v-col></v-row>
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance
          from-bottom
        >
          <v-row
            class="ml-8"
          >
            <v-col
              cols="6"
              class="d-inline-flex align-center"
            >
              <v-img
                src="/img/home-quote.png"
                class="travel-qt"
              ></v-img>
              <v-btn
                class="primary white--text font-weight-bold"
                large
              >
                <span class="px-4">Landlord Quote</span>
              </v-btn>
              <v-btn
                class="white learn font-weight-bold"
                large
              >
                <span class="secondary--text">Learn More</span>
              </v-btn>
            </v-col>
          </v-row>
        </sequential-entrance>
      </div>
      <div class="section2 pb-16">
        <v-row class="pt-16">
          <v-col
            class="d-flex justify-center"
          >
            <div class="travel-text pa-4">
              Find other insurance coverage you need
            </div>
          </v-col>
        </v-row>
        <v-row justify="center pb-16">
          <v-col
            cols="11"
            class="pb-16"
          >
            <v-row justify="center">
              <v-col cols="2">
                <router-link to="/condo">
                  <v-img
                    src="/img/condo-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/landlord">
                  <v-img
                    src="/img/landlord-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/renters">
                  <v-img
                    src="/img/renters-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/auto">
                  <v-img
                    src="/img/car-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/business">
                  <v-img
                    src="/img/buisiness-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/pet">
                  <v-img
                    src="/img/pet-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-----------------------------------TABLET---------------------------------------->
    <div v-show="tabletDevice">
      <div
        class="section1-tablet pb-16 small"
      >
        <sequential-entrance
          from-top
        >
          <v-row class="ml-16">
            <v-col class="pt-16">
              <div class="title-text-tablet">
                <v-row><v-col>Get a <span class="primary--text">landlord</span> insurance <br> quote and apply online</v-col></v-row>
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance
          from-bottom
        >
          <v-row
            class="ml-8 mt-16"
          >
            <v-col
              cols="6"
              class="d-inline-flex align-center"
            >
              <v-img
                src="/img/home-quote.png"
                class="travel-qt"
              ></v-img>
              <v-btn
                class="primary white--text font-weight-bold"
                large
              >
                <span class="px-4">Landlord Quote</span>
              </v-btn>
              <v-btn
                class="white learn font-weight-bold"
                large
              >
                <span class="secondary--text">Learn More</span>
              </v-btn>
            </v-col>
          </v-row>
        </sequential-entrance>
      </div>
      <div class="section2 pb-16">
        <v-row class="pt-16">
          <v-col
            class="d-flex justify-center"
          >
            <div class="travel-text pa-4">
              Find other insurance coverage you need
            </div>
          </v-col>
        </v-row>
        <v-row justify="center pb-16">
          <v-col
            cols="11"
            class="pb-16"
          >
            <v-row justify="center">
              <v-col cols="2">
                <router-link to="/condo">
                  <v-img
                    src="/img/condo-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/landlord">
                  <v-img
                    src="/img/landlord-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/renters">
                  <v-img
                    src="/img/renters-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/auto">
                  <v-img
                    src="/img/car-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/business">
                  <v-img
                    src="/img/buisiness-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="2">
                <router-link to="/pet">
                  <v-img
                    src="/img/pet-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>

    <!-----------------------------------MOBILE---------------------------------------->
    <div v-show="mobileDevice">
      <div
        class="section1-mobile pb-16 small"
      >
        <sequential-entrance
          from-top
        >
          <v-row class="d-flex justify-center">
            <v-col>
              <div class="text-h2 title-text-mobile pt-16 ml-6">
                <v-row><v-col>Get a <span class="primary--text">landlord</span> insurance <br> quote and apply online</v-col></v-row>
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance
          from-bottom
        >
          <v-row
            class="mt-16"
          >
            <v-col
              cols="12"
              class="d-inline-flex align-center justify-center"
            >
              <v-img
                src="/img/home-quote.png"
                class="renter-qt-mobile"
              ></v-img>
              <v-btn
                class="primary white--text font-weight-bold"
              >
                <span>Landlord Quote</span>
              </v-btn>
            </v-col>
          </v-row>
        </sequential-entrance>
      </div>
      <div class="section2 pb-16">
        <v-row class="pt-16">
          <v-col
            class="d-flex justify-center"
          >
            <div class="title-text-mobile text-center text-h2 pa-1">
              Find other insurance coverage you need
            </div>
          </v-col>
        </v-row>
        <v-row justify="center pb-16">
          <v-col
            cols="11"
            class="pb-16"
          >
            <v-row justify="center">
              <v-col cols="4">
                <router-link to="/condo">
                  <v-img
                    src="/img/condo-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="4">
                <router-link to="/landlord">
                  <v-img
                    src="/img/landlord-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
              <v-col cols="4">
                <router-link to="/renters">
                  <v-img
                    src="/img/renters-badge.png"
                    class="zoom"
                  ></v-img>
                </router-link>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Dashboard',

    data () {
      return {
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },
    },
    created () {
      this.$gtag.pageview('Dashboard')
      this.currentuser = this.$store.getters.getuser
    },

    methods: {
      navigateTo (product) {
        this.$router.push(product)
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      sendevent () {
        this.$gtag.event('blanket', { method: 'Dashboard', campaign: 'none' })
        return 'completed'
      },
    },
  }
</script>
<style scoped>
.title-text-mobile {
    font-weight: 500;
    color: #616161;
  }
.title-text-tablet {
  font-size: 60px;
  font-weight: 500;
  line-height: 132.19%;
  color: #616161;
  }
.title-text-small {
  font-size: 60px;
  font-weight: 500;
  line-height: 132.19%;
  color: #606060;
  }

.title-text-large {
  font-size: 60px;
  font-weight: 500;
  line-height: 132.19%;
  color: #616161;
  margin-top: 6rem;
  }

  .section1{
  position: relative;
  width: 100%;
  height: 900px;
  background: linear-gradient(to left, rgba(255,255,255,0) 40%, rgba(255,255,255,1) 100%),url( '/img/landlord-background.png') no-repeat center center;
  background-size: cover;
}

.section1-mobile{
  position: relative;
  max-height: 500px;
  background: linear-gradient(to top, rgba(255,255,255,0) 20%, rgba(255,255,255,1) 100%),url( '/img/landlord-background.png') no-repeat center center;
  background-size: cover;
  background-color: white;
  background-position: center;
}

.section1-tablet{
  position: relative;
  max-height: 500px;
  background: linear-gradient(to top, rgba(255,255,255,0) .5%, rgba(255,255,255,1) 100%),url( '/img/landlord-background.png') no-repeat center center;
  background-size: cover;
  background-color: white;
  background-position: right;
}

.section2{
  background-color: #FFFFFF;
}
.small {
    height: 700px;
}

.large {
    height: 900px;
}
.travel-qt {
  max-width:  120px;
  object-fit: scale-down;
  margin-right: -4rem;
  z-index: 5;
}

.learn {
  border: 1px solid #00A1B7 !important;
  color: #00A1B7 !important;
}

.travel-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #616161;
background-color: white;
}

.zoom {
    transition: transform .2s;
}

.zoom:hover {
    transform: scale(1.2);
}

.renter-qt-mobile {
  max-width:  100px;
  object-fit: scale-down;
  margin-right: -2rem;
  z-index: 5;
}
</style>
